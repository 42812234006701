import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import {Link, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import placeholderGames from "../images/ui/placeholder-games.png";
import {codesToNames} from "../utils/language";
import {useUIState} from "../app/uiContext";

const useStyles = makeStyles(theme => ({
    logo: {
        maxWidth: `200px`,
    },
    grid: {
        gridTemplateColumns: `7rem 1fr`,
    }
}));

export const GameDetailBlock = ({ game }) => {
    const { userLocale } = useUIState();
    const classes = useStyles();
    const gameImage = game.fields ? game.fields.logoUrl : placeholderGames;
    const gameInPrint = game.in_print ? `Yes` : `No`;
    const gameLanguages = game.languages ? codesToNames(game.languages).join(`, `) : `Unknown`;
    const dt = game.publication_date && new Date(game.publication_date);
    const gamePublicationDate = dt && dt.getTime && !isNaN(dt.getTime()) ? new Intl.DateTimeFormat(userLocale, {year: `numeric`, month: `long`}).format(dt) : `Unknown`;
    const gameWebsite = game.website ? <Link href={`https://`+game.website} rel="noopener" target="_blank">{game.website}</Link> : `N/A`;

    return (
        <>
            <Typography className="pb-8" component="h1" variant="h4">
                {game.name}
            </Typography>
                <div className={`flex flex-col flex-wrap sm:flex-row`}>
                    <div className="flex-initial">
                        <img
                            alt={game.name}
                            className={`mx-auto sm:m-0 ${classes.logo}`}
                            height="276"
                            src={gameImage}
                            title={game.name}
                            width="200"
                        />
                    </div>
                        <Paper className="flex-1 p-4">
                            <Typography color="textSecondary" component="div" variant="body2">
                                <dl className={`gap-2 grid ${classes.grid}`}>
                                    <dt className="text-right">Game:</dt><dd className="">{game.name}</dd>
                                    <dt className="text-right">Publisher:</dt><dd className="">{game.publisher}</dd>
                                    <dt className="text-right">Date published:</dt><dd className="">{gamePublicationDate}</dd>
                                    <dt className="text-right">In print:</dt><dd className="">{gameInPrint}</dd>
                                    <dt className="text-right">Languages:</dt><dd className="">{gameLanguages}</dd>
                                    <dt className="text-right">Official website:</dt><dd className="">{gameWebsite}</dd>
                                </dl>
                            </Typography>
                        </Paper>
                </div>
            {game.description && (
            <Paper className="mt-4">
                <Typography className="markdown p-4" color="textSecondary" component="div" variant="body2">
                    <ReactMarkdown>{game.description}</ReactMarkdown>
                </Typography>
            </Paper>
            )}
        </>
    )
};
